#root {
  background: linear-gradient(
    33deg,
    rgba(7, 9, 10, 1) 0%,
    rgba(28, 27, 41, 1) 47%,
    rgba(46, 43, 68, 1) 77%
  );
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(165, 165, 165);
  overflow: auto;
}

.container {
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 2rem;
  flex-direction: column;
  flex: 1;
  max-width: 75rem;
}

.card {
  display: flex;
  flex: 1;
  padding: 1rem;
  margin: 1rem;
  background-color: #1a1c1d;
  border-radius: 1rem;
  border: solid 1px #262829;
  z-index: 2;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 10px 10px 39px -5px rgba(0, 0, 0, 0.44);
}

.cardItem {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.avatar {
  width: 15rem;
  height: 15rem;
  margin: auto;
  border-radius: 150rem;
  overflow: hidden;
  border: solid 1px #4a4a4a;
  display: flex;
  margin-top: -10rem;
}

img {
  width: 91%;
  margin: auto;
  border-radius: 150rem;
}

.nameSection {
  margin-top: 1rem;
  text-align: center;
}

.nameSection p {
  font-size: 1.2rem;
}

.nameSection span {
  font-weight: 700 !important;
  color: #a594fa;
}

.nameHeader {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 0px;
  font-size: 3rem !important;
  font-weight: 600 !important;
  color: white;
}

p {
  margin: 0px;
}

.badge {
  display: flex;
  width: fit-content;
  text-align: center;
  justify-content: center;
  height: 2rem;
  font-size: 0.9rem;
  background: #2e3132;
  padding: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 15rem;
}

.badge p {
  padding-left: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  margin: auto;
}

.badge svg {
  margin: auto;
}

.badgeAvailable {
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.badgeTheme svg {
  color: #a594fa;
  font-size: 1.2rem;
}

.badgeTheme p {
  padding-left: 0.5rem;
}

.badgeAvailable svg {
  color: rgb(68, 255, 68);
}

.separator {
  border-top: solid 1px #4a4a4a;
  width: 80%;
  margin: auto;
  color: #1a1c1d00;
  margin-top: 1rem;
}

.skillsContainer {
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  display: flex;
  padding: 1.3rem;
  width: 80%;
  margin: auto;
  background-color: #1f2122;
  border-radius: 0.75rem;
  gap: 0.5rem; /* Space between items */
}

.btnSectionOneContainer {
  display: flex;
  width: 84.4%;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.buttonSectionOne {
  text-transform: capitalize !important;
  color: rgb(165, 165, 165) !important;
  flex: 1 !important;
  background-color: #1f2122 !important;
  border-radius: 0.75rem !important;
  padding: 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.buttonSectionOne:hover {
  background-color: #a594fa !important;
  color: #1a1c1d !important;
}

.cardInnerItem {
  display: flex;
  flex: 1;
}

.cardInnerItem .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-align: center;
  border-right: solid 1px #4a4a4a;
  padding: 1rem;
}

.cardInnerItem .left .text {
  font-size: 1rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: webkit-fill-available;
  max-width: 40rem;
  margin-bottom: 1rem;
}

.badgeNoneBG {
  background: none !important;
  margin: auto;
  font-size: 1rem;
}

.badgeNoneBG svg {
  color: #a594fa !important;
}

.headSmall {
  font-size: 1.5rem !important;
  font-weight: 600;
  color: white;
  margin-bottom: 2rem;
}

.cardInnerItem .right {
  display: flex;
  flex: 1;
  margin: auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 1rem;
  overflow: hidden;
  height: 0.31rem;
}

.progress-bar {
  height: 100%;
  background-color: #a594fa;
  border-radius: 13px;
  transition: width 0.3s ease-in-out;
}

.progressBar {
  width: 80%;
  margin: auto;
}

.progressBar p {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
}

.progressBar h1 {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

.counterContainer {
  display: flex;
  gap: 0.5rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.counter {
  background-color: #2e3132;
  padding: 1rem;
  border-radius: 0.75rem;
  width: 8rem;
}

.counter p {
  font-size: 5rem;
  font-weight: 600;
  color: white;
}

.skillList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 32rem;
  margin: auto;
}

.listAllItem {
  margin-top: 2rem;
}

.listAllItem p {
  text-align: center;
  width: fit-content;
  background: #1f2122;
  margin: auto;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 75rem;
  padding-left: 4rem;
  padding-right: 4rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.MuiImageListItem-root img {
  border-radius: 1rem;
  width: 100%;
}

.footer p {
  margin: auto;
  text-align: center;
  margin: 3rem;
  color: white;
  font-weight: 600;
}

.sampleProjectImgContainer {
  width: 80%;
  margin: auto;
}

.sampleProjectImgContainer img {
  margin: auto;
  width: 100%;
  border-radius: 2rem;
  border: solid 1px #d2d2d2;
  transition: all 0.3s ease-in-out;
}

.sampleProjectImgContainer img:hover {
  opacity: 0.8;
  cursor: pointer;
}

.smapleProjectDesc {
  text-align: center;
  width: 70%;
  margin: auto;
  padding-top: 1.5rem;
  display: flex;
  padding: 1.5rem;
  background-color: #1a1c1d;
  border-radius: 1.5rem;
  border: solid 1px #262829;
  z-index: 2;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 10px 10px 39px -5px rgba(0, 0, 0, 0.75);
}

.smapleProjectDesc h1 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  border-radius: 0px;
  background: none;
  color: rgb(255, 255, 255);
}

.smapleProjectDesc p {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: .5rem;
  border-radius: 0px;
  background: none;
  text-align: left;
}
