body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
  .skillList {
    width: 20rem !important;

  }

  .counterContainer {
    flex-direction: column;
  }
  .cardInnerItem {
    flex-direction: column;
  }

  .cardInnerItem .left {
    border-right: none !important;
  }


  .cardInnerItem .right {
    width: 95% !important;
    margin-top: 2rem !important;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Width of the vertical scrollbar */
::-webkit-scrollbar {
  width: 0.2rem;
}

/* Height of the horizontal scrollbar */
::-webkit-scrollbar {
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6a6a6a;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a594fa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Optional: Customize the corner where horizontal and vertical scrollbars meet */
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/* Optional: Style for the buttons (arrows) on the scrollbar */
::-webkit-scrollbar-button {
  background: #ccc;
}
